import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ResetSvg } from "../../../svg";
import { ButtonLinkStyled } from "../../../styles/CommonStyled";
import { removeDiacritics, transformImage } from "../../../common/utils";
import Loader from "../../../common/Loader";
import ShowMediaLoader from "../../../components/media/ShowMediaLoader";
import ShowMediaPay from "../../../components/media/ShowMediaPay";
import { MainContext } from "../../../ContextState";

let LIMIT_TEXT = 600;

export const PageItemWrapperStyled = styled.div`
    //border-radius: 8px;
    //border: 1px solid rgba(0, 0, 0, 0.20);
    width: 100%;
    margin-bottom: 40px;

    @media (max-width: 1200px) {
    }

    h3 {
        color: #000;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 30px 0 30px;

        @media (max-width: 1200px) {
            margin: 10px 0 20px;
        }
    }
`;

export const ContentStyled = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 40px;
    padding: 5px 25px;

    @media (max-width: 630px) {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
`;

export const HorizontalLineStyled = styled.div`
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 60%;
    margin: auto;
`;

export const ContentInfoStyled = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    width: 102%;

    @media (max-width: 1200px) {
        width: 100%;
    }

    button {
        &:hover {
            cursor: ${ ({ isPreviewPage }) => (isPreviewPage ? "unset" : "pointer") };
            text-decoration: ${ ({ isPreviewPage }) => (isPreviewPage ? "unset" : "underline") };
        }

        svg {
            display: ${ ({ isPreviewPage }) => (isPreviewPage ? "none" : "block") };
        }
    }
`;

export const TextCounterStyled = styled.div`
    font-size: 15px;
    display: grid;
    justify-content: end;
`;

export const SimulatePageStyled = styled.div`
    width: 814px;
    position: fixed;
    top: 10px;
    right: -6000px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: grid;
`;

export const SimulatePageTextStyled = styled.p`
    width: 60%;
    text-align: center;
    margin: auto;
    display: grid;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    line-height: 1.3;
    white-space: pre-line;
`;

export const TextEditStyled = styled.div`
    textarea {
        font-family: "Varela Round", sans-serif;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: #fff;
        width: 100%;
        height: 150px;
        resize: none;
        color: #000;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 10px;

        @media (max-width: 1200px) {
            box-sizing: border-box;
        }

        @media (max-width: 760px) {
            min-height: calc(var(--line-height) * 2);
            padding: 10px;
            font-size: 16px;
            line-height: var(--line-height);
            height: 200px;
        }

        @media (max-width: 630px) {
            font-size: 19px;
        }
    }

    p {
        color: red;
        margin: 0;
        padding: 0;
    }

    &.invalid {
        textarea {
            border: 1px solid red;
        }
    }
`;

export const MediaEditStyled = styled.div`
    padding: 30px;
    position: relative;

    @media (max-width: 1200px) {
        padding: 10px 0;
    }

    @media (max-width: 630px) {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 81% 18.4%;
        grid-gap: 10px;
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
    }
`;

export const MediaEditMainImageStyled = styled.div`
    position: relative;

    img {
        width: 100%;
        border-radius: 8px;
    }

    &.prev {
        img {
            visibility: hidden;
        }
    }
`;

export const MediaEditThumbsImageStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 11px;

    @media (max-width: 630px) {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 11px;
    }
`;

export const MediaEditThumbItemImageStyled = styled.div`
    cursor: pointer;
    position: relative;

    img {
        width: 100%;
        border-radius: 8px;
        box-sizing: border-box;
        border-bottom: 6px solid transparent;

        @media (max-width: 630px) {
            width: 100%;
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 86%;
        background: rgba(0, 0, 0, 0.4);
        pointer-events: none;
        border-radius: 8px;
    }

    &.selected {
        &:before {
            background: rgba(0, 0, 0, 0);
        }

        img {
            border-bottom: 6px solid #ffc43d;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            transition: border 0.3s ease;
        }
    }
`;

export const MoreMediaStyled = styled.div`
    width: 100%;
    text-align: center;

    p {
        margin: 0;
        padding: 0;
    }

    span {
        margin-inline-start: 5px;
        text-decoration: underline;
        cursor: pointer;
    }
`;

const PAGE_HEIGHT = {
    default: "805px",
    "66bdbdba3e314dbad68940b8": "210mm", // USA Lulu
    "67065c9a3592c638ec3470a4": "180mm" // Danish solentro
};

const PageItemEdit = ({
                          reset,
                          item,
                          idxxx,
                          langData,
                          textData,
                          handleTextChange,
                          imagesData,
                          handleImageChange,
                          isPreview,
                          generateMoreImages,
                          moreImagesJobIds,
                          isPreviewLoad,
                          isPreviewPage,
                          progress,
                          bookPosition,
                          regenerationsLeft,
                          clientId,
                          limitTextOverride
                      }) => {
    const textSimPageRef = useRef();
    const wrapperSimRef = useRef();

    const { textAreaValidationContext } = useContext(MainContext);
    const [textAreaValidation, s_textAreaValidation] = textAreaValidationContext;

    const [isTextValid, setIsTextValid] = useState(true);

    const isPreviewItem = isPreview && item?.images?.length === 0;
    const isPreviewLoadItem = isPreviewLoad && item?.images?.length === 0;

    const labelRefs = [];
    let images = item.images;

    const textWithoutMikud = removeDiacritics(textData);

    const [textLimit, s_textLimit] = useState(textWithoutMikud?.length);

    if(limitTextOverride){
        LIMIT_TEXT = limitTextOverride;
    }

    useEffect(() => {
        if (!textSimPageRef?.current) {
            return;
        }
        if (!wrapperSimRef?.current) {
            return;
        }

        const wrapper = wrapperSimRef.current;
        const textPage = textSimPageRef.current;
        const textPageHeight = textPage.offsetHeight;
        const wrapperHeight = wrapper.offsetHeight;
        if (textPageHeight + 5 > wrapperHeight) {
            setIsTextValid(false);
            s_textAreaValidation({ ...textAreaValidation, [idxxx]: false });
        } else {
            const temp = { ...textAreaValidation };
            delete temp[idxxx];
            s_textAreaValidation(temp);
            setIsTextValid(true);
        }
    }, [textSimPageRef?.current, wrapperSimRef?.current, textLimit]);

    const imagesLength = images?.length || 0;

    if (!imagesLength) {
        images = [item.us_u1, item.us_u2, item.us_u3, item.us_u4];
    }

    const isInGenerating = moreImagesJobIds?.find((pp) => pp.idx === idxxx);
    const showMoreImages = regenerationsLeft > 0 && !isInGenerating;

    let transformedImageData = transformImage(imagesData, "w_800");
    if (isPreviewItem) {
        transformedImageData = "https://print.mebook.ai/static/frame/blur.png?width=220";
    }

    const simulateHeight = PAGE_HEIGHT?.[clientId] || PAGE_HEIGHT?.default;

    return (
        <PageItemWrapperStyled>
            <SimulatePageStyled style={ { height: simulateHeight } } ref={ wrapperSimRef }>
                <SimulatePageTextStyled ref={ textSimPageRef }>{ textData }</SimulatePageTextStyled>
            </SimulatePageStyled>
            <ContentStyled>
                <TextEditStyled className={ isTextValid ? "" : "invalid" }>
                    <h3>{ `${ langData.page } ${ idxxx + 1 }` }</h3>
                    { !isTextValid && <p>{ langData.edit_text_validation?.text_is_too_big }</p> }
                    <textarea
                        className={ `koidzd-doos-${ idxxx }` }
                        disabled={ isPreview }
                        aria-label={ `${ langData.arias.edit_book_page }  ${ idxxx + 1 }` }
                        aria-placeholder={ `${ langData.arias.textArea } ${ idxxx + 1 }` }
                        onChange={ (e) => {
                            const textWithoutMikud = removeDiacritics(e.target.value);
                            const len = textWithoutMikud.length;
                            if (len <= LIMIT_TEXT) {
                                s_textLimit(textWithoutMikud.length);
                                handleTextChange(e.target.value);
                            }
                        } }
                        value={ textData }
                    />
                    <ContentInfoStyled isPreviewPage={ isPreviewPage }>
                        <ButtonLinkStyled
                            className={ `kjfchzx-pooidjx-${ idxxx }` }
                            aria-label={ isPreviewPage ? langData.reset_dis : langData.reset }
                            onClick={ () => reset(idxxx) }
                        >
                            { isPreviewPage ? langData.reset_dis : langData.reset } <ResetSvg />
                        </ButtonLinkStyled>
                        <TextCounterStyled>
                            { textLimit }/{ LIMIT_TEXT }
                        </TextCounterStyled>
                    </ContentInfoStyled>
                </TextEditStyled>
                <MediaEditStyled>
                    <MediaEditMainImageStyled>
                        <img src={ transformedImageData } alt="main pic" className={ isPreviewItem ? "prev" : "" } />
                        { isPreviewItem && !isPreviewLoadItem && (
                            <ShowMediaPay
                                label={ langData.preview.image_blurred_text }
                                tabIndex="0"
                                text={ langData.preview.image_blurred_text }
                            />
                        ) }
                        { isPreviewLoadItem && (
                            <ShowMediaLoader
                                langData={ langData }
                                progress={ progress }
                                position={ bookPosition }
                                label={ langData.more_data_prepearing }
                                tabIndex="0"
                                text={ langData.more_data_prepearing }
                            />
                        ) }
                    </MediaEditMainImageStyled>
                    { !isPreviewItem && (
                        <MediaEditThumbsImageStyled
                            className={ `item-xoip-${ idxxx }` }
                            role="radiogroup"
                            aria-label={ `${ langData.arias.choose_page_image } ${ idxxx + 1 }` }
                        >
                            { images.map((img, idx) => {
                                const transformedIma = transformImage(img, "w_800");
                                const isChecked = transformedImageData === transformedIma;
                                let isFirstChecked = false;
                                if (!transformedImageData && idx === 0) {
                                    isFirstChecked = true;
                                }

                                let tabIndexValue = "-1";
                                if (isChecked || isFirstChecked) {
                                    tabIndexValue = "0";
                                }

                                labelRefs[idx] = React.createRef();

                                const selected = transformedImageData === transformedIma;

                                return (
                                    <MediaEditThumbItemImageStyled
                                        ref={ labelRefs[idx] }
                                        onKeyDown={ (e) => {
                                            if (e.key === "ArrowLeft" || e.key === "ArrowDown") {
                                                if (images[idx + 1]) {
                                                    handleImageChange(images[idx + 1], idx + 1);
                                                    labelRefs[idx + 1].current.focus();
                                                }
                                            }
                                            if (e.key === "ArrowRight" || e.key === "ArrowUp") {
                                                if (images[idx - 1]) {
                                                    handleImageChange(images[idx - 1], idx - 1);
                                                    labelRefs[idx - 1].current.focus();
                                                }
                                            }
                                        } }
                                        tabIndex={ tabIndexValue }
                                        aria-checked={ transformedImageData === transformedIma }
                                        aria-label={ `${ langData.arias.image_number } ${ idx + 1 }` }
                                        role="radio"
                                        className={ selected ? "selected" : "" }
                                        onClick={ () => {
                                            handleImageChange(img, idx);
                                        } }
                                        key={ img + idx }
                                    >
                                        <img src={ transformedIma } alt="thumb" />
                                    </MediaEditThumbItemImageStyled>
                                );
                            }) }
                        </MediaEditThumbsImageStyled>
                    ) }
                    { showMoreImages && (
                        <MoreMediaStyled>
                            <p>
                                { langData.more_images_editor }
                                <span onClick={ generateMoreImages }>{ langData.more_images_editor_2 }</span>
                            </p>
                        </MoreMediaStyled>
                    ) }
                    { isInGenerating && <Loader size={ 30 } /> }
                </MediaEditStyled>
            </ContentStyled>
            <HorizontalLineStyled />
        </PageItemWrapperStyled>
    );
};

export default PageItemEdit;
