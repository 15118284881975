import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getFontSize, getSizeByLetters, makeImgAsPrint } from "../../../../common/utils";

const WrapperStyled = styled.div`
    width: 100%;
    height: 100%;
`;

export const TextStyled = styled.div`
    position: absolute;
    bottom: 10%;
    text-align: center;
    width: 100%;
    text-shadow: 1px 1px 2px #000;
    padding: 0 7px;
    box-sizing: border-box;

    .draggable-point {
        color: #fff;
        position: absolute;
        top: -31px;
        width: 80px;
        text-align: center;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 0px 5px;
        height: 55px;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(000, 000, 000, 0.5);
        border-radius: 7px;
        cursor: grab;
        user-select: none;

        div {
            line-height: 0;
            font-size: 61px;
            padding: 0;
            margin: 0;
        }

        @media (max-width: 460px) {
            width: 58px;
            grid-gap: 0px 0px;
            height: 46px;
        }
    }
`;

const ContStyled = styled.div`
    position: relative;

    img {
        border-radius: 3px;
        display: block;
        object-fit: cover;
    }

    box-shadow:
        0 0 5px -1px black,
        inset -1px 1px 2px rgba(255, 255, 255, 0.5);
    margin: auto;
    border-radius: 5px;
    background-size: cover;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        pointer-events: none;
        border-radius: 5px;
    }

    &.selected,
    &.partOfBook {
        &:before {
            background: rgba(0, 0, 0, 0);
        }
    }

    @media (max-width: 767px) {
        width: 100%;
        height: inherit;
    }
`;

export const DEFAULT_WIDTH = 365;

const BookCoverPrev = ({
    imageUrl,
    lang,
    text,
    coverDesign,
    index,
    isSelected,
    isForCapture,
    partOfBook,
    bookId,
    isPreview,
    contWidth = 350,
    wrapperWidth,
    hideText,
    coverData
}) => {
    const wrapperRef = useRef(null);
    const [height, s_height] = useState(contWidth);
    const [backgroundImage, setBackgroundImage] = useState(imageUrl);

    useEffect(() => {
        s_height(contWidth);
    }, [contWidth]);

    useEffect(() => {
        if (isForCapture) {
            let printImage = makeImgAsPrint(imageUrl);
            printImage = `${printImage}?width=900`;

            const imageUrlToBase64 = (url, callback) => {
                try {
                    const img = new Image();
                    img.crossOrigin = "Anonymous";
                    let imageLoaded = false;
                    img.onload = () => {
                        imageLoaded = true;
                        try {
                            const canvas = document.createElement("canvas");
                            const ctx = canvas.getContext("2d");
                            canvas.width = img.width;
                            canvas.height = img.height;
                            ctx.drawImage(img, 0, 0);
                            const dataURL = canvas.toDataURL("image/png");
                            callback(dataURL);
                        } catch (e) {
                            console.error("Canvas processing error:", e);
                            callback(null);
                        }
                    };
                    img.onerror = (e) => {
                        console.error("Image load error:", e);
                        callback(null);
                    };
                    img.src = url;

                    // Add a timeout to handle cases where the image fails to load
                    setTimeout(() => {
                        if (!imageLoaded) {
                            console.error("Image load timeout");
                            callback(null);
                        }
                    }, 5000); // 5 seconds timeout
                } catch (e) {
                    console.error("Image processing error:", e);
                    callback(null);
                }
            };

            imageUrlToBase64(printImage, (base64) => {
                if (base64) {
                    setBackgroundImage(() => base64);
                } else {
                    console.error("Failed to convert image to base64");
                    setBackgroundImage(() => imageUrl); // Fallback to original image URL
                }
            });
        } else {
            setBackgroundImage(() => imageUrl);
        }
    }, [imageUrl, isForCapture]);

    useEffect(() => {
        if (wrapperRef?.current) {
            s_height(wrapperRef.current.offsetWidth);
        }
    }, [wrapperRef?.current, contWidth]);

    let background = `linear-gradient(to right, rgb(60, 13, 20) 2px, rgba(255, 255, 255, 0.5) 5px, rgba(255, 255, 255, 0.25) 7px, rgba(255, 255, 255, 0.25) 8px, transparent 12px, transparent 12px, rgba(255, 255, 255, 0.25) 16px, transparent 19px), url(${backgroundImage})`;
    if (lang === "he") {
        background = `linear-gradient(to left, rgb(60, 13, 20) 2px, rgba(255, 255, 255, 0.5) 5px, rgba(255, 255, 255, 0.25) 7px, rgba(255, 255, 255, 0.25) 8px, transparent 12px, transparent 12px, rgba(255, 255, 255, 0.25) 16px, transparent 19px), url(${backgroundImage})`;
    }

    const coverText = coverData?.text || text;
    const textColor = coverData?.textColor || coverDesign?.textColor || "fff";
    const textSize = coverDesign?.textSize;

    const newTextSize = getSizeByLetters(coverText);
    let fontSize = getFontSize(newTextSize, partOfBook);
    if (coverData?.textSize) {
        fontSize = coverData.textSize;
    }

    if (height !== 300) {
        const percent = (height / 300) * 100; // cal
        const increaseFor = percent - 100;
        const plusNumber = (fontSize / 100) * increaseFor;
        fontSize = fontSize + plusNumber;
    }

    const bookCoverId = `book-cover-${index}`;

    let classes = `book-prev-component lang-${lang}`;
    if (isSelected) {
        classes += " selected-book-page";
    }
    if (isForCapture) {
        classes += " capture-po selected-book-page";
    }
    if (bookId) {
        classes += ` bookId-${bookId}`;
    }

    const style = {
        backgroundImage: background,
        backgroundSize: "cover",
        width: contWidth,
        height
    };

    if (wrapperWidth < 1200 && wrapperWidth > 800) {
        style.height = wrapperWidth / 5;
        style.width = wrapperWidth / 5;
    }

    if (isForCapture) {
        style.height = 700;
        style.width = 700;
    }

    let contClasses = isSelected ? "selected kkop" : "kkop";
    if (partOfBook) {
        contClasses += " partOfBook";
    }
    if (hideText) {
        contClasses += " hideText";
    }
    if (isForCapture) {
        contClasses += " selected";
    }

    let bottomStyle = "10%";
    if (coverData?.position) {
        if (coverData?.position?.y === undefined) {
            bottomStyle = "10%";
        } else {
            bottomStyle = `${coverData?.position?.y}%`;
        }
    }

    return (
        <WrapperStyled ref={wrapperRef} id={bookCoverId} className={classes}>
            <ContStyled className={contClasses} style={style}>
                {!hideText && (
                    <TextStyled
                        style={{
                            bottom: bottomStyle,
                            fontSize: `${fontSize}px`,
                            color: textColor
                        }}
                    >
                        {coverText}
                    </TextStyled>
                )}
            </ContStyled>
        </WrapperStyled>
    );
};

export default BookCoverPrev;
