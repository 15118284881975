import React, { useEffect } from "react";
import { CLIENTS } from "../consts";

const loadGoogleAnalytics = (GA_ID) => {
    const gaScript = document.createElement("script");
    gaScript.async = true;
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${ GA_ID }`;
    gaScript.onload = () => {
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            window.dataLayer.push(arguments);
        }

        window.gtag = gtag; // Attach the function to the window object
        gtag("js", new Date());
        gtag("config", GA_ID);
        console.log("GA Loaded");
    };
    document.head.appendChild(gaScript);
};

const loadGoogleTagManager = (GTM_ID) => {
    const gtmScript = document.createElement("script");
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${ GTM_ID }`;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: "gtm.js", "gtm.start": new Date().getTime() });
    document.head.appendChild(gtmScript);

    const noscript = document.createElement("noscript");
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${ GTM_ID }" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(noscript);
    console.log("GTM Loaded");
};

const AnalyticsManager = ({ clientId }) => {
    const [loaded, setLoaded] = React.useState(false);

    useEffect(() => {
        if (!loaded && clientId) {
            if (clientId === CLIENTS.AMIR_DENMARK.id) { // amir
                const GA_ID = "GTM-MN2LLDHS";
                loadGoogleTagManager(GA_ID);
            } else {
                // mebook
                let GTM_ID = "G-XG8MR1JK7R";
                if(clientId === CLIENTS.USA.id) {
                    GTM_ID = "G-B9S1E84RT5"; // mebook USA
                }
                loadGoogleAnalytics(GTM_ID);
            }
            setLoaded(true);
        }
    }, [clientId]);

    return null;
};

export default AnalyticsManager;
