import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router";
import { MainContext } from "../ContextState";
import { getBook, getConfig } from "../ApiCalls";
import LogRocket from "logrocket";
import { APP_SETTINGS } from "../settings";
import Loader from "../common/Loader";
import Header from "./editor/components/Header";
import { getTokenCookie, setClientIdCookie } from "../common/cookie";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import { identifyUser } from "../common/eventManager";
import AnalyticsManager from "../common/Analitics";
import { CLIENTS } from "../consts";
import { getMobileQuery, isWithinTimeRanges } from "../common/utils";

const MainWrapperStyled = styled.div`
    @media (max-width: 1200px) {
        width: 100%;
    }

    .loader-lottie.full-page {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
`;

if (process.env.NODE_ENV === "production") {
    if(isWithinTimeRanges()) {
        LogRocket.init("uamlxw/frame-prod", {
            rootHostname: "mebook.ai",
        });
    }

    Sentry.init({
        dsn: "https://ab0cfec146f51be5071ddd5f9ae7efd9@o4506189547044864.ingest.sentry.io/4506189555564544",
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ["localhost", "mebook"]
            }),
            new Sentry.Replay()
        ],
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

mixpanel.init("8b3a99edcc90e93fdf660d440d64eeaf", {
    debug: false,
    track_pageview: true,
    persistence: "cookie",
    cookie_domain: ".mebook.ai"
});

const Wrapper = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(location.search);
    const lang = queryParameters.get("language") || "he";
    const bookId = queryParameters.get("bookId");
    const clientId = queryParameters.get("clientId") || CLIENTS.MEBOOK.id;
    const token = getTokenCookie();
    const innerWidth = getMobileQuery();
    const isMobile = innerWidth < 768;

    const { bookDataContext, configContext } = useContext(MainContext);
    const [bookData, s_bookData] = bookDataContext;
    const [config, s_config] = configContext;

    const [loading, s_loading] = useState(true);
    const [callForBook, s_callForBook] = useState(false);

    const goTo = (url) => {
        navigate(`${url}${location.search}`);
    };

    useEffect(() => {
        if (lang === "he") {
            document.body.style.direction = "rtl";
        } else {
            document.body.style.direction = "ltr";
        }
    }, [lang]);

    const goToOtp = () => {
        const frameUrl = config.frameUrl || "https://frame.mebook.ai";
        const currentUrl = window.location.href;
        window.location.href = `${frameUrl}/otp?clientId=${clientId}&language=${lang}&returnUrl=${encodeURIComponent(currentUrl)}`;
    };

    // http://localhost:3003/?bookId=67179eaa907d38c40ec38afe&language=da&clientId=67065c9a3592c638ec3470a4
    useEffect(() => {
        if (!token && config) {
            goToOtp();
        } else {
            s_callForBook(true);
        }
    }, [token, config]);

    useEffect(() => {
        if (clientId) {
            if (clientId === CLIENTS.SFARIM.id) {
                setClientIdCookie(clientId);
            }
        }
    }, []);

    useEffect(() => {
        if (clientId && !config) {
            getConfig(lang, clientId)
                .then((data) => {
                    s_config(data);
                })
                .catch((e) => {
                    console.log("e", e);
                });
        }
    }, []);

    useEffect(() => {
        // israel pixel
        let FB_PIXEL_ID = "1097268434915469";
        if (clientId === CLIENTS.USA.id) {
            FB_PIXEL_ID = "563364439447860";
        }

        const fbScript = document.createElement("script");
        fbScript.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${FB_PIXEL_ID}');
      fbq('track', 'PageView');
    `;
        document.head.appendChild(fbScript);

        return () => {
            // Cleanup scripts when component unmounts
            document.head.removeChild(fbScript);
        };
    }, []);

    useEffect(() => {
        if (!bookData && bookId && config && callForBook) {
            getBook(bookId)
                .then((data) => {
                    s_bookData(data);

                    LogRocket.identify(bookId, {
                        email: data.endUserEmail
                    });
                    identifyUser(bookData?.phone);

                    switch (data.status) {
                        case APP_SETTINGS.bookStatus.inProcess:
                        case APP_SETTINGS.bookStatus.NOT_STARTED:
                            if (location.pathname !== APP_SETTINGS.pages.preview) {
                                goTo(APP_SETTINGS.pages.preview);
                            } else {
                                s_loading(false);
                            }
                            break;
                        case APP_SETTINGS.bookStatus.pendingPayment:
                            if (location.pathname !== APP_SETTINGS.pages.preview) {
                                goTo(APP_SETTINGS.pages.preview);
                            } else {
                                s_loading(false);
                            }
                            break;
                        case APP_SETTINGS.bookStatus.approved:
                        case APP_SETTINGS.bookStatus.GENERATING_PRINT_FILES:
                        case APP_SETTINGS.bookStatus.PRINT_FILES_GENERATED:
                        case APP_SETTINGS.bookStatus.GENERATING_PRINT_FILES_FAILED:
                        case APP_SETTINGS.bookStatus.CREATION_FAILED:
                        case APP_SETTINGS.bookStatus.COMPLETION_FAILED:
                            const showUrl = config?.showUrl || "https://show.mebook.ai";
                            const url = `${showUrl}?bookId=${bookId}&language=${lang}&clientId=${clientId}`;
                            window.location.href = url;
                            break;
                        case APP_SETTINGS.bookStatus.returned:
                            if (location.pathname !== APP_SETTINGS.pages.returned) {
                                goTo(APP_SETTINGS.pages.returned);
                            } else {
                                s_loading(false);
                            }
                            break;
                        case APP_SETTINGS.bookStatus.pendingUserApproval:
                            if (location.pathname !== APP_SETTINGS.pages.edit) {
                                goTo(APP_SETTINGS.pages.edit);
                            } else {
                                s_loading(false);
                            }
                            break;
                    }
                    s_loading(false);
                })
                .catch((e) => {
                    console.log("errrrar", e);
                    goToOtp();
                });
        } else {
            // s_loading(false);
        }
    }, [location.pathname, config, bookId, callForBook]);

    useEffect(() => {
        if(!bookId){
            const url = "https://mebook.ai";
            window.location.href = url;
        }
    }, []);

    let classes = "";
    if (lang === "he") {
        classes = "lang-he";
    }

    return (
        <MainWrapperStyled className={classes}>
            <AnalyticsManager clientId={ clientId } />
            {loading && <Loader fullPage={true} size={isMobile ? 200 : 300} />}
            {!loading && (
                <>
                    <Header clientId={clientId} lang={lang} />
                    <Outlet />
                </>
            )}
        </MainWrapperStyled>
    );
};

export default Wrapper;
