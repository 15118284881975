// export const PAYMENT_URL = 'http://wordpress-684254-4342059.cloudwaysapps.com';
export const PAYMENT_URL = "https://mebook.ai";
// export const API_URL = 'https://qa-api.mebook.ai/api';
export const API_URL = "https://api.mebook.ai/api";
// export const API_URL = "http://localhost:5001/api";
//  export const SOCKET_URL = 'https://qa-api.mebook.ai';
export const SOCKET_URL = "https://api.mebook.ai";
// export const SOCKET_URL = "http://localhost:5001";


export const CLIENTS = {
    MEBOOK: {
      id: "64d491633684ef0d32edb473"
    },
    SFARIM:{
        id: "64bece04cc5cefd035c880f2"
    },
    USA: {
        id: "66bdbdba3e314dbad68940b8"
    },
    AMIR_DENMARK: {
        id: "67065c9a3592c638ec3470a4"
    },
    PHILIPINO:{
        id: "6728e87e9475da3c7d89876b"
    }
}
