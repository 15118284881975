const eng = () => {
    return {
        arias: {
            cover: "Cover number",
            textArea: "Text of book, page",
            choose_page_image: "Choose page image"
        },
        page: "Page",
        reset: "Reset",
        reset_dis: "Edit will be available after purchase",
        finish: "Finish",
        finished: {
            book: {
                one: "Cool, now it's our turn to do the magic",
                second: "{{name}}'s book will ",
                third: "arrive in {{days_num}} work days to:"
            },
            mebook_info: {
                one: "Don't hesitate to contact us whenever it suits you -"
            },
            pdf: {
                one: "Looks amazing",
                second: "Your book will be sent to",
                third: "in few minutes",
                fourth: "We hope you enjoy :)"
            },
            to_watch_status: "To watch the status of your book, please go to",
            status_title: "Status"
        },
        general_generating_book_error: {
            error: "We have a bad day, some error happened, we are fixing"
        },
        already_finished: {
            first: "This book is already finished",
            second: ""
        },
        returned_book: {
            first: "This book is canceled"
        },
        main_title: "Please edit your book to finish",
        choose_cover: "Pick Story Cover",
        subtitle: "After purchase, fine-tune every page to your liking. Not happy with an image? No problem! Generate new ones. Still not happy? Talk to us, we’ll get you sorted out, or refund 100% of your payment.",
        subtitle_2: "The character created for you is inspired by your child. If you're not satisfied with the resemblance, you can upload a new photo, and we'll be happy to update the character accordingly.",
        errorCode: {},
        choose: "Choose",
        choose_hard_cover: "Complete my order",
        choose_e_book: "Complete my order",
        complete_subtitle: "After payment you can edit the story and send it to print",
        choose_hard_cover_2: "Buy Hard Cover book",
        choose_e_book_2: "Buy Digital Book",
        save: "Save",
        to_buy: "Complete Your Order",
        preview_and_make_pdf: "Preview and send book",
        preview_and_make_book: "Preview and print book",
        tutorial_title: "Tutorial",
        print: "Print",
        tutorial_items: [
            "Please choose an image for every page",
            "You can edit the text of the page",
            "You can save and edit later",
            "You can edit the cover of the page",
            "To finish please press Preview and send book "
        ],
        end_process: {
            main_text: "Amazing! Pick the one of the options you want \n" + "to set for your book cover",
            buttonText: "Checkout",
            choose_cover_pdf: "Instant Digital Magic",
            choose_cover_hard: "It’s time to make them smile",
            p_description_pdf:
                "A high-quality digital e-book in PDF format, exquisitely designed and readable from anywhere at any time.",
            p_description_hard: "Complete your order and get your hardcover personalized book right to your doorstep",
            price_pdf: "",
            price_book: "+ delivery",
            free_delivery: "Free Shipping",
            free_delivery_filipino: "Free Shipping Inside Manila",
            price_book_sub: ""
        },
        loading_texts: {
            text1: "Sparkling imagination...",
            subtext1: "Just a few magical moments away!",
            text2: "Unleashing creativity...",
            subtext2: "Your personalized adventure is brewing!",
            text3: "Embrace the anticipation...",
            subtext3: "Your AI book is in the making!",
            text4: "Immerse in wonder...",
            subtext4: "Your personalized adventure is brewing!",
            text5: "Captivating moments ahead...",
            subtext5: "We're crafting a story just for you!"
        },
        tutorial_checkbox: "Do not show again",
        terms: "agree to the website's ",
        terms_part_2: "terms of use",
        terms_link: "https://mebook.ai/en/refund_returns/",
        click_me: "Preview Story",
        tutorial_button_text: "Start",
        terms_modal_h:
            "This agreement outlines the terms and conditions governing purchases made through MeBook’s website and mobile applications. It also provides details regarding our returns and refunds policy.",
        terms_modal: [
            {
                title: "Placing Orders",
                p: "You may place orders for personalized children’s books through our website or mobile apps. All orders require submission of details about your child along with payment information. By placing an order, you confirm that all information provided is accurate."
            },
            {
                title: "Pricing and Payment",
                p: "The prices displayed include all applicable taxes and charges. Payment must be made in full at the time of purchase via credit card or any other payment option made available. MeBook reserves the right to update prices at any time."
            },
            {
                title: "Order Confirmation and Processing",
                p: "You will receive an order confirmation email with details of your purchase. Book creation and printing will begin as soon as payment is successfully processed. Estimated delivery timelines will be communicated in the order confirmation."
            },
            {
                title: "Shipping and Delivery",
                p: "We will ship your completed book to the address specified at checkout. Delivery timelines may vary based on your location. MeBook cannot be held responsible for delays caused by third-party delivery partners."
            },
            {
                title: "Returns and Refunds",
                p:
                    "If your book is damaged during shipping or differs significantly from the preview provided, please contact us within 7 days of delivery to initiate a return. We will assess the damage or discrepancy claimed and may request photographic evidence." +
                    "If approved, you will be refunded the full purchase price once the book is returned to our fulfilment center. Return shipping costs will be reimbursed if the reason for return is due to damage or errors on our part."
            },
            {
                title: "Cancelling or Changing Orders",
                p: "Given the personalized nature of our books, orders cannot be cancelled or changed once book creation has begun. Please review order details carefully before placing your order."
            },
            {
                title: "Limitation of Liability",
                p: "MeBook will not be liable for any indirect, incidental, or consequential damages arising from the purchase or use of our products. Our maximum liability is limited to the purchase price of the book."
            },
            {
                title: "Contact Us",
                p: "If you have any questions or concerns regarding your order, please contact us at info@mebook.ai. We are committed to a fun, easy ordering experience and your complete satisfaction."
            }
        ],
        tutorial_modal: {
            cov: "Pick Story Cover",
            items: "Choose Your Favorite Image for Each Page",
            texts: "You can edit text for every page",
            reset: "You can reset the text to it's original",
            save: "You can save book for future editing",
            finish: "To finish the book please press finish button",
            skip: "Skip",
            next: "Next",
            back: "Back",
            last: "Last"
        },
        tutorial_modal_preview: {
            cov: "Please choose cover",
            items: "Please choose image for every page",
            texts: "You can edit text for every page",
            reset: "You can reset the text to it's original",
            save: "You can save book for future editing",
            finish: "To finish the book please press finish button"
        },
        preview: {
            image_blurred_text: "The image will be displayed after the purchase"
        },
        back_to_website: "Back to website",
        more_images_editor: "Not quite right?",
        more_images_editor_2: "Generate more!",
        more_images_editor_warn:
            "We're dedicated to making your book truly special. If the images generated aren't quite what you had in mind, feel free to create a new set! Just click \"Generate Again\" and let our AI work its magic once more.\n" +
            "\n" +
            "Please keep in mind that to ensure a smooth and efficient experience, you can regenerate images for up to 10 pages per book.",
        more_images_editor_warn_left: "Remaining",
        more_images_editor_warn_left_1: "attempts",
        more_images_button_warn: "Confirm",
        more_data_prepearing: "Crafting a Magical Image",
        past_books: "Past books",
        after_book_sent: {
            title: "After sending the book for production:",
            text1: "Please note that once the book is produced, no changes can be made.",
            text2: "Ensure that the child’s name is",
            text3: "and that there are no spelling errors in the story if you have made changes to it.",
            text4: "Also, make sure that the marked pictures are the ones you liked the most out of the possible four.",
            button: "Confirm",
            edited_cover:
                "The system has detected that you have changed the title of the book, please confirm that the change in the title is to your liking and approved for printing."
        },
        validation_popup: {
            confirm: "Confirm",
            cancel: "Cancel",
            heading: "Review Your Character",
            confirm_message: "Are you happy with this image as the basis for your personalized book?",
            warning_title: "Important:",
            warning:
                "Remember: Once your adventure begins, the magic can't be changed. All illustrations will be based on this enchanted image.",
            original: "Original Image",
            mebook: "MeBook Image"
        },
        edit_text_validation: {
            text_is_too_big: "Text is too big",
            errors_of_pages: "There are errors in pages",
            errors_of_page: "There are errors in page"
        },
        queue_text: "Your position in queue:",
        title_editor_text: {
            f_size: "Font Size",
            f_color: "Font Color",
            hex_color: "HEX Code",
            preview: "Preview",
            save: "Save & Continue",
            goback: "Go Back to Editor",
            preview_mode: "You are in preview mode",
            edit_text: "Edit Cover",
            reset: "Reset",
            text_is_too_big: "Text is too big"
        },
        consent_popup: {
            title: "Please Review your Images",
            warning: "Please note that once book is produced , no changes can be made",
            warning_2: "AI technology tends to create anomalies, such as extra fingers or mismatched text and images. Look for anomalies and choose images without them. Of course, it's very funny, so feel free to share with your kids!",
            buttons: {
                back: "Back to Edit",
                continue: "Agree & Continue"
            },
            consent_box: {
                title: "Possible Malformations",
                consent: "I agree, I have checked all the images properly and there is no malformation"
            },
            instructions: (name) => [
                {
                    title: (
                        <p>
                            Ensure that the child's name is <strong>{name}</strong> and that there are no spelling
                            errors in the story if you have made changes to it.
                        </p>
                    )
                },
                {
                    title: (
                        <p>
                            Also, make sure that the marked pictures are the ones you liked the most out of the possible
                            four.
                        </p>
                    )
                }
            ]
        },
        general: {
            next: "Next",
            pageTitle: "Book creation",
            try_again: "Try again"
        },
        preebook_title: "Your books",
        preebook_subTitle: "Books below are not in cart",
        otp: {
            title: "Share your phone number",
            title_email: "Share your email",
            title_2: "SMS with the code is on the way",
            title_2_email: "Email with the code is on the way",
            code_is_invalid: "Code is invalid",
            change_phone_number: "Change phone number",
            send_again: "Resend"
        },
        fields: {
            name_male: "Child's Name",
            name_female: "Child's Name",
            age_hero: "Age",
            gender: {
                male: "Male",
                female: "Female"
            },
            age: "Age:",
            apply: "Save",
            email: "Email",
            phone: "Phone number",
            email_validation: "Wrong email format",
            sex: "Gender",
            age_validation: "Age must be"
        }
    };
};

export default eng;
