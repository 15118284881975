import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ClickOnMe from "../../editor/components/ClickOnMe";
import BookPreview from "../../editor/components/book/BookPreview";
import Button from "./Button";
import { MainContext } from "../../../ContextState";
import { getMobileQuery } from "../../../common/utils";
import { useLocation } from "react-router";
import { CLIENTS } from "../../../consts";

const WrapperStyled = styled.div`
    width: 727px;
    min-height: 320px;
    display: grid;
    grid-gap: 12px;
    margin-top: 25px;

    .complet-subtitle {
        margin: 10px 0 0;
    }

    .complet-subtitle-2{
        margin: 3px 0 0;
    }
    
    @media (max-width: 1130px) {
        margin: auto;
    }

    @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
    }
`;

const CheckBoxStyled = styled.div`
    width: 300px;
    margin: auto;

    input {
        display: inline-block;
        vertical-align: top;
    }

    label {
        display: inline-block;
        //line-height: 1.2;
        vertical-align: top;
        margin-inline-start: 5px;
    }

    .terms-link {
        cursor: pointer;
        color: #5d35ff;
    }
`;

export const GettingPaidBaseStyled = styled.div`
    display: grid;
    grid-template-columns: ${ ({ isSingleColumn }) => (isSingleColumn ? "1fr" : "1fr auto 1fr") };
    margin: 10px auto 0;
    grid-gap: 18px;

    @media (max-width: 1000px) {
        grid-template-columns: 1fr ${ ({ isSingleColumn }) => (isSingleColumn ? "" : "1fr") };
    }

    @media (max-width: 767px) {
        margin: 5px auto 10px;
        width: 100%;
        grid-template-columns: ${ ({ isSingleColumn }) => (isSingleColumn ? "1fr" : "1fr 1fr") };
    }

    .vertical-pay {
        @media (max-width: 767px) {
            display: none;
        }
    }

    .pay-book {
        .cont {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            position: relative;
            text-align: center;
            justify-items: center;

            @media (max-width: 767px) {
                justify-items: center;
                display: grid;
                grid-template-rows: 1fr 2fr 2fr;
            }

            h3 {
                margin-bottom: auto;
                margin-top: auto;
                min-height: 40px;
                font-size: ${ ({ isSingleColumn }) => (isSingleColumn ? "2rem" : "1.5rem") };

                @media (max-width: 767px) {
                    font-size: ${ ({ isSingleColumn }) => (isSingleColumn ? "24px" : "1rem") };
                }
            }

            .choose-cover {
                font-size: 22px;
                height: max-content;
                padding: 12px 14px;
                width: fit-content;
                margin: auto;

                @media (max-width: 767px) {
                    font-size: 24px;
                }
            }

            .price {
                text-decoration: underline;
                font-size: 26px;
                font-weight: 400;
                margin-bottom: 5px;

                &.client-6728e87e9475da3c7d89876b {
                    font-size: 22px;
                }

                @media (max-width: 767px) {
                    margin: 10px 0 2px;
                    margin: ${ ({ isSingleColumn }) => (isSingleColumn ? "2px 0 2px;" : "10px 0 2px;") };
                    font-size: 22px;
                }
                @media (max-width: 410px) {
                    font-size: 18px;
                }
            }

            p {
                font-size: 19px;
                min-height: 40px;
                margin: 0 0 1px;

                @media (max-width: 767px) {
                    min-height: auto;
                    display: none;
                }
            }
        }
    }

    .sub-title {
        font-size: ${ ({ isSingleColumn }) => (isSingleColumn ? "20px" : "14px") };
        width: max-content;
        bottom: -6px;
        left: 1px;

        @media (max-width: 767px) {
            font-size: 12px;
            top: 20px;
            bottom: inherit;
        }
        @media (max-width: 340px) {
            top: inherit;
            bottom: -5px;
        }
    }

    &.desc {
        @media (max-height: 744px) {
            margin-top: 2px;

            .pay-book .cont {
                h3 {
                    font-size: 24px;
                }

                p {
                    min-height: auto;
                    font-size: ${ ({ isSingleColumn }) => (isSingleColumn ? "20px" : "14px") };
                }

                .price {
                    font-size: ${ ({ isSingleColumn }) => (isSingleColumn ? "22px" : "18px") };

                    .sub-title {
                        font-size: ${ ({ isSingleColumn }) => (isSingleColumn ? "16px" : "12px") };
                    }
                }
            }
        }
    }

    .discountPrice {
        position: relative;
        color: #FFA927;
    }

    .discountPriceBefore {
        font-size: 21px;
        color: #FFA927;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background: red;
            //transform: rotate(165deg);
            top: 45%;
        }
    }

    &.single-column {
        .pay-book {
            .cont {
                @media (max-width: 767px) {
                    justify-items: center;
                    display: flex;
                }
            }
        }
    }
`;

export const VerticalLineStyled = styled.div`
    height: 200px;
    width: 1px;
    background: rgba(0, 0, 0, 0.12);

    &.vertical-line-stage2 {
        margin-top: 112px;
        margin-inline-start: 9px;
        margin-inline-end: 33px;
    }

    @media (max-width: 1000px) {
        display: none;
    }
`;

const PreviewBookAndPay = ({
                               selectedBookCoverImage,
                               bookData,
                               lang,
                               langData,
                               textData,
                               imagesData,
                               chooseBookHandle,
                               onTermsClick,
                               loading
                           }) => {
    const wrapperRef = useRef(null);
    const termsRef = useRef(null);
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const clientId = queryParameters.get("clientId");
    const { configContext } = useContext(MainContext);
    const [config] = configContext;

    const [width, s_width] = useState(0);
    const [onFocus] = useState(0);

    const innerWidth = getMobileQuery();
    const isMobile = innerWidth < 550;

    useEffect(() => {
        if (wrapperRef?.current) {
            s_width(wrapperRef.current.offsetWidth);
        }
    }, [wrapperRef]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (onFocus) {
                switch (event.key) {
                    case " ": // space
                    case "Enter":
                        onTermsClick();
                        break;
                    default:
                        break;
                }
            }
        };

        const divElement = termsRef.current;

        if (divElement) {
            divElement.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            if (divElement) {
                divElement.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [onFocus]);

    const gender = bookData.inputData.gender;

    const templateTitle = gender === "female" ? bookData.template?.titleFemale : bookData.template?.titleMale;
    const coverDesign = bookData.template.coverDesign;
    const book = [];
    textData.forEach((text, idx) => {
        book.push({ img: imagesData[idx] || "https://print.mebook.ai/static/frame/blur.png" });
        book.push({ text: text });
    });

    let bookPrice = "";
    let pdfPrice = "";
    if (lang === "he") {
        bookPrice = `${ config.priceSymbol }${ config.bookPrice } ${ langData.end_process.price_book } ${ config.priceSymbol }${ config.deliveryPrice }`;
        pdfPrice = `${ config.priceSymbol }${ config.pdfPrice } ${ langData.end_process.price_pdf }`;
    } else {
        bookPrice = `${ config.priceSymbol }${ config.bookPrice } ${ langData.end_process.price_book }  ${ config.priceSymbol }${ config.deliveryPrice }`;

        if (config.deliveryPrice === 0) {
            if (CLIENTS.PHILIPINO.id === clientId) {
                bookPrice = `${ config.priceSymbol }${ config.bookPrice } ${ langData.end_process.free_delivery_filipino }`;
            } else {
                bookPrice = `${ config.priceSymbol }${ config.bookPrice } ${ langData.end_process.free_delivery }`;
            }
        }

        pdfPrice = `${ langData.end_process.price_pdf } ${ config.priceSymbol }${ config.pdfPrice }`;
    }

    let isSingleColumn = true;
    if (config.pdfActive) {
        isSingleColumn = false;
    }

    const isUsaClient = clientId === CLIENTS.USA.id;
    const isMebookClient = clientId === CLIENTS.MEBOOK.id;
    const isPhilipinoClient = clientId === CLIENTS.PHILIPINO.id;
    const isAmirClient = clientId === CLIENTS.AMIR_DENMARK.id;

    let paidClasses = `lang-${ lang } ${ isMobile ? "mob" : "desc" }`;
    if (isSingleColumn) {
        paidClasses += " single-column";
    }

    let pdfButtonText = langData.choose_e_book;
    let bookButtonText = langData.choose_hard_cover;
    if (isPhilipinoClient) {
        pdfButtonText = langData.choose_e_book_2;
        bookButtonText = langData.choose_hard_cover_2;
    }

    return (
        <WrapperStyled ref={ wrapperRef }>
            <ClickOnMe langData={ langData } lang={ lang } />
            <BookPreview
                width={ width }
                bookCoverData={ {
                    img: selectedBookCoverImage,
                    text: templateTitle,
                    coverDesign
                } }
                lang={ lang }
                langData={ langData }
                book={ book }
            />
            { isSingleColumn ? (
                <GettingPaidBaseStyled isSingleColumn={ isSingleColumn } className={ paidClasses }>
                    <div className="pay-book hard">
                        <div className="cont">
                            <h3>{ langData.end_process.choose_cover_hard }</h3>
                            <p>{ langData.end_process.p_description_hard }</p>
                            <div className={ `price client-${ clientId }` }>
                                { isUsaClient && (
                                    <div>{ bookPrice.split(" ").slice(1).join(" ") }</div>
                                ) }
                                { !isUsaClient && (
                                    <div>{ bookPrice }</div>
                                ) }
                                {/*{isMebookClient && (*/ }
                                {/*    <div>*/ }
                                {/*        <span className="price-wrapper"><span className="discountPriceBefore">{ config.priceSymbol }127</span> <span className="discountPrice">{ config.priceSymbol }{ config.bookPrice }</span> </span>{ langData.end_process.price_book } { config.priceSymbol }{ config.deliveryPrice}*/ }
                                {/*    </div>*/ }
                                {/*)}*/ }
                                <span className="sub-title">{ langData.end_process.price_book_sub }</span>
                            </div>
                            <Button
                                ariaLabel={ `${ langData.end_process.choose_cover_hard } ${ bookPrice }` }
                                loading={ loading === "makingCover_book" }
                                not_clickable={ loading === "makingCover_pdf" }
                                onClick={ () => chooseBookHandle("book") }
                                className="next-button choose-cover"
                            >
                                { bookButtonText }
                            </Button>
                        </div>
                    </div>
                </GettingPaidBaseStyled>
            ) : (
                <GettingPaidBaseStyled isSingleColumn={ isSingleColumn } className={ `lang-${ lang } ${ isMobile ? "mob" : "desc" }` }>
                    <div className="pay-book hard">
                        <div className="cont">
                            <h3>{ langData.end_process.choose_cover_hard }</h3>
                            <p>{ langData.end_process.p_description_hard }</p>
                            <div className={ `price client-${ clientId }` }>
                                <div>{ bookPrice }</div>
                                <span className="sub-title">{ langData.end_process.price_book_sub }</span>
                            </div>
                            <Button
                                ariaLabel={ `${ langData.end_process.choose_cover_hard } ${ bookPrice }` }
                                loading={ loading === "makingCover_book" }
                                not_clickable={ loading === "makingCover_pdf" }
                                onClick={ () => chooseBookHandle("book") }
                                className="next-button choose-cover"
                            >
                                { bookButtonText }
                            </Button>
                        </div>
                    </div>
                    <VerticalLineStyled className="vertical-pay" />
                    <div className="pay-book pdf">
                        <div className="cont">
                            <h3>{ langData.end_process.choose_cover_pdf }</h3>
                            <p>{ langData.end_process.p_description_pdf }</p>
                            <div className="price">{ pdfPrice }</div>
                            <Button
                                ariaLabel={ `${ langData.end_process.choose_cover_pdf } ${ pdfPrice }` }
                                loading={ loading === "makingCover_pdf" }
                                not_clickable={ loading === "makingCover_book" }
                                onClick={ () => chooseBookHandle("pdf") }
                                className="next-button choose-cover"
                            >
                                { pdfButtonText }
                            </Button>
                        </div>
                    </div>
                </GettingPaidBaseStyled>
            ) }
            <p className="complet-subtitle">{ langData.complete_subtitle }</p>
            { isAmirClient && (
                <p className="complet-subtitle-2">{ langData.complete_subtitle_2 }</p>
            ) }
        </WrapperStyled>
    );
};

export default PreviewBookAndPay;
