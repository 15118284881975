import React from "react";
import styled from "styled-components";

import Lottie from "react-lottie-player";

// import animation from '../images/magic-book.json';
import animation from "../images/circle.json";

const LoaderWrapper = styled.div`
    text-align: center;
`;

const Loader = ({ size = 80, fullPage }) => {
    let classs = "loader-lottie";
    if(fullPage) {
        classs += " full-page";
    }

    return (
        <LoaderWrapper className={classs}>
            <Lottie loop animationData={animation} play style={{ width: size, height: size, margin: "auto" }} />
        </LoaderWrapper>
    );
};

export default Loader;
