import mixpanel from "mixpanel-browser";

export function trackEvent(eventName) {
    try {
        window?.gtag("event", eventName, {
            event_category: "editor",
            event_label: eventName
        });
    } catch (e) {
        console.log(e);
    }
    try {
        window?.fbq("track", eventName, {
            content_name: "editor"
        });
    } catch (e) {
        console.log(e);
    }
    try {
        window.dataLayer = window?.dataLayer || [];
        window.dataLayer.push({
            event: eventName,
            eventCategory: "editor",
            eventLabel: eventName
        });
    } catch (e) {
        console.log(e);
    }
}

export function identifyUser(phone) {
    try {
        mixpanel.identify(phone);

        mixpanel.people.set({
            $phone: phone
        });
    } catch (e) {
        console.log(e);
    }
}

export function trackMixEvent(eventName, subEvent, bookId, message = "", clientId) {
    try {
        mixpanel.track(eventName, {
            subEvent: subEvent,
            bookId: bookId,
            message: message,
            clientId: clientId
        });
    } catch (e) {
        console.log(e);
    }
}
