import styles from "./Validation.module.css";
import { MainContext } from "../../../ContextState";
import { useContext } from "react";
import { trackMixEvent, trackEvent } from "../../../common/eventManager";

export default function ValidationPopup({ onClose, selectedCoverImage, onNext, lang, langData, clientId }) {
    const { bookDataContext } = useContext(MainContext);
    const [bookData] = bookDataContext;

    const handleConfirm = () => {
        trackEvent("editor_face_validation_next_click")
        trackMixEvent(
            "preview_page_purchase_dialog_purchase_button_clicked",
            "click",
            bookData._id,
            "",
            clientId
        );
        onNext();
    }

    return (
        <div className={styles.content}>
            <div className={styles.spacerY}></div>

            <h2 className={styles.heading}>{langData.validation_popup.heading}</h2>
            <div className={styles.col}>
                <div className={styles.original}>
                    <div className={styles.label} color="white">
                        {langData.validation_popup.original}
                    </div>
                    <img src={bookDataContext[0].inputData.faceImageUrl ?? ""} alt="faceImageUrl" />
                </div>
                <div className={styles.arrow_spacer}>
                    <Arrow></Arrow>
                </div>
                <div className={styles.book}>
                    <div className={styles.label} data-color="yellow">
                        {langData.validation_popup.mebook}
                    </div>
                    <img src={selectedCoverImage ?? ""} alt="selectedCoverImage" />
                </div>
            </div>
            <div className={styles.warning_title}>{langData.validation_popup.warning_title}</div>
            <div className={styles.warning}>
                <WarningIcon />
                <p>{langData.validation_popup.warning}</p>
            </div>
            <div className={styles.buttonrow}>
                <div
                    className={styles.button}
                    data-type="close"
                    onClick={() => {
                        trackMixEvent(
                            "preview_page_purchase_dialog_edit_back_clicked",
                            "click",
                            bookData._id,
                            "",
                            clientId
                        );
                        onClose();
                    }}
                >
                    <EditIcon></EditIcon>
                    <div className={styles.spacer_x} data-space="1"></div>
                    <p>{langData.validation_popup.cancel}</p>
                </div>
                <div className={styles.spacer_x}></div>
                <div
                    className={styles.button}
                    data-type="submit"
                    onClick={handleConfirm}
                >
                    <p>{langData.validation_popup.confirm}</p>
                    <ChevonRight lang={lang}></ChevonRight>
                </div>
            </div>
        </div>
    );
}

const WarningIcon = () => {
    return (
        <svg width="20" height="20" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.909 2.782a2.25 2.25 0 0 1 2.975.74l.083.138 7.759 14.009a2.25 2.25 0 0 1-1.814 3.334l-.154.006H4.242A2.25 2.25 0 0 1 2.2 17.812l.072-.143L10.03 3.66a2.25 2.25 0 0 1 .879-.878ZM12 16.002a.999.999 0 1 0 0 1.997.999.999 0 0 0 0-1.997Zm-.002-8.004a1 1 0 0 0-.993.884L11 8.998 11 14l.007.117a1 1 0 0 0 1.987 0l.006-.117L13 8.998l-.007-.117a1 1 0 0 0-.994-.883Z"
                fill="currentColor"
            />
        </svg>
    );
};
const EditIcon = () => {
    return (
        <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.94 5 19 10.06 9.062 20a2.25 2.25 0 0 1-.999.58l-5.116 1.395a.75.75 0 0 1-.92-.921l1.395-5.116a2.25 2.25 0 0 1 .58-.999L13.938 5Zm7.09-2.03a3.578 3.578 0 0 1 0 5.06l-.97.97L15 3.94l.97-.97a3.578 3.578 0 0 1 5.06 0Z"
                fill="currentColor"
            />
        </svg>
    );
};
const ChevonRight = () => {
    return (
        <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.293 4.293a1 1 0 0 0 0 1.414L14.586 12l-6.293 6.293a1 1 0 1 0 1.414 1.414l7-7a1 1 0 0 0 0-1.414l-7-7a1 1 0 0 0-1.414 0Z"
                fill="currentColor"
            />
        </svg>
    );
};

const Arrow = () => {
    return (
        <svg width="36" height="36" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.704 4.284a1 1 0 1 0-1.403 1.424L17.67 11H4a1 1 0 1 0 0 2h13.665L12.3 18.285a1 1 0 0 0 1.403 1.424l6.925-6.822a1.25 1.25 0 0 0 0-1.78l-6.925-6.823Z"
                fill="currentColor"
            />
        </svg>
    );
};
