import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import Modal from "./modal/Modal";
import { MainContext } from "../ContextState";
import { ReactComponent as TrashIcon } from "../images/trash-icon.svg";

const WrapperStyled = styled.div`
    width: 100%;
    margin: 50px auto;
    position: relative;

    @media (max-width: 420px) {
        width: 100%;
    }
`;

const HaChupchikStyled = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    top: -60px;
`;

const ContainerStyled = styled.div``;

const TitleStyled = styled.div`
    font-size: 14px;
`;

const BooksStyled = styled.div`
    &:hover {
        .me-pook {
            margin-inline-start: -7px;
            transition: margin-inline-start 0.5s ease;
        }
    }
`;

const BookStyled = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-inline-start: -10px;
    transition: margin-inline-start 0.5s ease;

    img {
        width: 25px !important;
        height: 25px !important;
        border-radius: 50%;
        border: 3px solid #fff;
    }
`;

const ModalListStyled = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    padding: 15px;
    overflow: auto;
    height: 70dvh;
    max-height: 530px;

    a {
        color: #000;
        text-decoration: none;
    }

    @media (max-width: 1130px) {
        width: 100%;
    }

    @media (max-width: 420px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        padding: 10px;

        a {
            width: 100%;
            display: grid;

            img {
                width: 100%;
            }
        }
    }
`;

const ModalItemStyled = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ModalItemTitleStyled = styled.div``;

const ModalItemContStyled = styled.div`
    position: relative;

    .main-image-prebook {
        width: 150px;

        @media (max-width: 460px) {
            width: 100%;
            height: auto;
            border-radius: 4px;
            object-fit: cover;
        }
    }
`;

const ModalItemContTitleStyled = styled.div`
    margin-bottom: 9px;

    h3,
    h4 {
        padding: 0;
        margin: 5px 0;
        font-size: 20px;
    }

    h4 {
        font-size: 18px;
    }

    hr {
        width: 70%;
    }
`;

const RemoveStyled = styled.div`
    position: absolute;
    right: 4px;
    top: 4px;
    background: #ffa927;
    border-radius: 50%;
    width: 30px;
    height: 30px;

    svg {
        width: 20px;
        height: 20px;
        margin: 4px;
    }

    @media (max-width: 460px) {
        right: 4px;
        top: 4px;
        display: grid;
        align-items: center;
        justify-items: center;
        width: 20px;
        height: 20px;

        svg {
            width: 14px;
            height: 14px;
            margin: 3px;
        }
    }
`;

const RecentPrebooks = ({ items, lang, langData, removePrebook }) => {
    const exampleModalRef = useRef(null);
    const [openSelection, setOpened] = useState(false);

    const { configContext } = useContext(MainContext);
    const [config] = configContext;

    const openSelectionFunc = () => {
        setOpened(!openSelection);
        document.body.classList.remove("modal-active");
    };

    const items6 = items.slice(0, 6);

    return (
        <WrapperStyled>
            <HaChupchikStyled>
                <ContainerStyled onClick={openSelectionFunc}>
                    <TitleStyled>{langData.past_books}</TitleStyled>
                    <BooksStyled>
                        {items6.map((item) => {
                            const image = item?.bookPagesData?.[0]?.images?.[0];

                            return (
                                <BookStyled className={"me-pook"} key={item._id}>
                                    <img src={`${image}?width=150`} alt="" />
                                </BookStyled>
                            );
                        })}
                    </BooksStyled>
                </ContainerStyled>
            </HaChupchikStyled>

            {openSelection && (
                <Modal
                    clickRef={exampleModalRef}
                    role="alertdialog"
                    isOpen={true}
                    onClose={openSelectionFunc}
                    lang={lang}
                    backGroundClose={true}
                    ariaTitle={langData.arias.exampleDialogTitle}
                >
                    <div className="popup_container">
                        <ModalItemContTitleStyled>
                            <h3>{langData.preebook_title}</h3>
                            <h4>{langData.preebook_subTitle}</h4>
                            <hr />
                        </ModalItemContTitleStyled>
                        <ModalListStyled>
                            {items.map((item) => {
                                const image = item?.bookPagesData?.[0]?.images?.[0];
                                const lang = item.template?.languageKey || "he";

                                let editorUrl = `https://editor.mebook.ai`;
                                if (config.editorUrl) {
                                    editorUrl = `${config.editorUrl}`;
                                }

                                return (
                                    <a
                                        key={item._id}
                                        href={`${editorUrl}?bookId=${item._id}&token=null&language=${lang}&clientId=${item.client}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <ModalItemStyled>
                                            <ModalItemTitleStyled>{item.title}</ModalItemTitleStyled>
                                            <ModalItemContStyled>
                                                <RemoveStyled
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        removePrebook(item._id);
                                                    }}
                                                >
                                                    <TrashIcon fill="#fff" alt="Remove" />
                                                </RemoveStyled>
                                                <img
                                                    src={`${image}?width=150`}
                                                    alt="Remove"
                                                    className="main-image-prebook"
                                                />
                                            </ModalItemContStyled>
                                        </ModalItemStyled>
                                    </a>
                                );
                            })}
                        </ModalListStyled>
                    </div>
                </Modal>
            )}
        </WrapperStyled>
    );
};

export default RecentPrebooks;
